<template>
  <div id="top_nav">
   <div class="cont">
     <div class="cont-white-border"></div>
     <img v-if="$vuetify.rtl" class="doroob-ar" src="../../../assets/logo.svg" alt="Doroob logo">
     <img v-else class="doroob-en" src="../../../assets/logoEn.svg" alt="Doroob logo">
     <v-spacer></v-spacer>
     <div class="item h-100" v-for="(item, i) in $t('nav')" :key="i">
       <router-link class="position-relative" :to="paths[i]">
         {{item}}
         <span class="active-line" :class="$vuetify.rtl ? 'ar' : 'en'"></span>
       </router-link>
     </div>
     <v-spacer></v-spacer>
<!--     <v-menu id="top_lang" v-if="!sideNav" transition="slide-y-transition" bottom offset-y>-->
<!--       <template v-slot:activator="{ on: menu, attrs }">-->
<!--         <v-tooltip bottom :open-delay="500">-->
<!--           <template v-slot:activator="{ on: tooltip }">-->
<!--             <v-btn class="lang" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">-->
<!--               <img class="indicator" src="../../../assets/icons/indicator.svg">-->
<!--               <span v-if="$vuetify.rtl">العربية</span>-->
<!--               <span v-else class="text-capitalize">English</span>-->
<!--               <img src="../../../assets/icons/langBtn.svg">-->
<!--             </v-btn>-->
<!--           </template>-->
<!--           <span>{{ $t("language") }}</span>-->
<!--         </v-tooltip>-->
<!--       </template>-->
<!--       <v-list>-->
<!--         <v-list-item @click="$setLang('ar')">-->
<!--           <v-list-item-title>العربية</v-list-item-title>-->
<!--         </v-list-item>-->
<!--         <v-list-item @click="$setLang('en')">-->
<!--           <v-list-item-title>English</v-list-item-title>-->
<!--         </v-list-item>-->
<!--       </v-list>-->
<!--     </v-menu>-->
       <div v-click-outside="closeMenu" v-if="!sideNav" class="language-container">
<!--         <template>-->
<!--           <vue-simple-select v-model="selected" class="lang-select" :options="options" />-->
<!--         </template>-->
         <div @click="ToggleMenu" :class="[$vuetify.rtl && 'flex-row-reverse', 'language flex-row-reverse']">
           <div>{{selected.label}}</div>
           <img id="arrow" :class="[this.$vuetify.rtl ? 'ml-1' : 'mr-1', 'indicator']" src="../../../assets/icons/indicator.svg">
         </div>
         <img :class="[$vuetify.rtl ? 'mr-2' : 'ml-2', 'd-block']" src="../../../assets/icons/langBtn.svg">
         <div v-if="this.isOpen" class="custom-dropdown-menu">
           <div id="transition" class="custom-dropdown-content">
             <div v-for="option in options" :key="option.value" @click="setLang(option.value)" class="custom-dropdown-item">{{option.label}}</div>
           </div>
         </div>
       </div>
       <a id="menu-icon" class="absolute-toggle" :class="[$vuetify.rtl ? 'ar' : 'en', $store.state.side ? 'close' : '']"
          v-show="sideNav" v-on:click="sideStatus($store.state.side)">
         <div class="bar"></div>
         <div class="bar"></div>
         <div class="bar"></div>
       </a>
   </div>
  </div>
</template>

<script>
import Direction from '../../Mixins/Lang/Direction'
// import VueSimpleSelect from 'vue-simple-custom-select'
export default {
  name: 'TopNav',
  // components: { VueSimpleSelect },
  mixins: [Direction],
  data () {
    return {
      sideNav: false,
      paths: ['/', '/#about', '/#vehicle_types', '/#service_centers', '/faqs', '/#contact'],
      options: [{ label: 'العربية', value: 'ar' }, { label: 'English', value: 'en' }],
      selected: {},
      isOpen: false
    }
  },
  created () {
    window.addEventListener('resize', this.fetchNav)
    this.selected = this.$vuetify.rtl ? { label: 'العربية', value: 'ar' } : { label: 'English', value: 'en' }
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      this.handleLang(val)
      this.$store.state.side = false
    })
    this.$watch(() => {
      return this.sideNav
    }, val => {
      this.isOpen = false
    })
  },
  mounted () {
    this.fetchNav()
  },
  methods: {
    closeMenu () {
      if (this.isOpen) {
        document.getElementById('arrow').classList.remove('rotate')
        document.getElementById('transition').classList.remove('is-open')
        setTimeout(() => {
          this.isOpen = false
        }, 300)
      }
    },
    ToggleMenu () {
      if (!this.isOpen) {
        this.isOpen = true
        setTimeout(() => {
          document.getElementById('transition').classList.add('is-open')
          document.getElementById('arrow').classList.add('rotate')
        })
      } else {
        this.closeMenu()
      }
    },
    handleLang (value) {
      this.selected = value ? { label: 'العربية', value: 'ar' } : { label: 'English', value: 'en' }
    },
    sideStatus (val) {
      this.$store.state.side = !val
    },
    setLang (val) {
      this.$setLang(val)
      this.closeMenu()
    },
    fetchNav () {
      this.sideNav = window.innerWidth < 900
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/main";
.absolute-toggle {
  cursor: pointer;
  position: fixed;
  top: 24px;
}
.absolute-toggle.ar {
  left: 19px;

}
.absolute-toggle.en {
  right: 19px;
}
a#menu-icon {
  display: inline-block;
  padding: 10px;
}
a#menu-icon .bar {
  margin: 3px;
  width: 35px;
  height: 3px;
  background: $--orange;
  border-radius: 1.5px;
  transition: margin 0.2s, transform 0.2s;
  -webkit-transition: margin 0.2s, -webkit-transform 0.2s;
  transition: margin 0.2s, -webkit-transform 0.2s;
  -o-transition: margin 0.2s, transform 0.2s;
  transition: margin 0.2s, transform 0.2s;
  transition: margin 0.2s, transform 0.2s, -webkit-transform 0.2s;
}
a#menu-icon:not(.close):hover {
  padding: 6px 10px;
}
a#menu-icon:not(.close):hover .bar {
  margin: 5px 3px;
}
a#menu-icon.close .bar:first-child {
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}
a#menu-icon.close .bar:nth-child(2) {
  opacity: 0;
}
a#menu-icon.close .bar:last-child {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
#top_nav {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background: rgba(18, 17, 24, 0.88);
  font-size: 13.5px!important;
  color: #FFFFFF;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 70px;
  padding: 0rem 1rem 0rem 1rem;
}
//@media screen and (max-width: 850px){
//  #top_nav {
//    backdrop-filter: blur(3px);
//  }
//}
.cont {
  display: flex; align-items: flex-end;
  width: 100%; height: 100%;
  padding: 0rem 1rem 0.8rem 1rem;
  //border-bottom: 2px solid #FFFFFF;
  position: relative;
}
.cont .cont-white-border {
  position: absolute;
  background: #FFFFFF;
  height: 2px;
  bottom: -2px;
  right: 0px;
  width: calc(100%);
}
.item {
  height: 100%;
  margin: 0 3vw;
  display: flex; align-items: flex-end;
}
.doroob-ar {
  width: 98px;
  height: 28.69px;
}
.doroob-en {
  //width: 118px;
  //height: 23.69px;
  width: 127px;
  height: 25.69px
}
@media screen and (max-width: 900px){
  .item {
    display: none;
  }
}
@media screen and (max-width: 1200px){
  .item {
   margin: 0 2vw;
  }
}
.v-btn {
  height: fit-content !important;
  width: fit-content !important;
}
.router-link-exact-active .active-line {
  background: orange;
  position: absolute;
  bottom: -15px;
  height: 2px;
}
.router-link-exact-active .active-line.ar {
  right: -50%;
  width: 200%;
}
.router-link-exact-active .active-line.en {
  right: -25%;
  width: 150%;
}
.toggle-line1, .toggle-line2 {
  width: 40px; height: 2px; background: $--orange;
  margin-top: 6px;
  -webkit-transition: all .5s;
  transition: all .5s;
}
.top {
  width: 35px!important;
  margin: 0!important;
  transform: rotateZ(45deg);
  //margin-top: -18px;
}
.bottom {
  width: 35px!important;
  transform: rotateZ(-45deg);
  margin-top: -2px!important;
}
.toggle-cont {
  width: 40px;
  height: 14px;
  cursor: pointer;
  transition: all .5s;
}
</style>
<!--<style lang="scss">-->
<!--@import "../../../assets/sass/main";-->
<!--/*.v-menu__content&#45;&#45;fixed {*/-->
<!--/*  border-radius: 20px!important;*/-->
<!--/*}*/-->
<!--.lang-select {-->
<!--  span.vue-simple-select-selected-option{-->
<!--    font-family: lama-extraBoldExpanded;-->
<!--  }-->
<!--  button.vue-simple-select-button{-->
<!--    display: flex;-->
<!--    flex-direction: row-reverse;-->
<!--    padding: 0 !important;-->
<!--    border: none;-->
<!--  }-->
<!--  svg.w-6.h-6.vue-simple-select-icon {-->
<!--    color: $&#45;&#45;orange;-->
<!--  }-->
<!--  button.vue-simple-select-button:focus{-->
<!--    outline: none !important;-->
<!--    border: none !important;-->
<!--  }-->
<!--  button.vue-simple-select-button:hover{-->
<!--    background: none !important;-->
<!--  }-->
<!--}-->
<!--.vue-simple-select-container .vue-simple-select-option.selected{-->
<!--  background: rgb(242,242,242) !important;-->
<!--  color: #383838 !important;-->
<!--}-->
<!--button.vue-simple-select-button:focus,-->
<!--.vue-simple-select-container button:focus{-->
<!--  outline: none !important;-->
<!--}-->
<!--ul.vue-simple-select-list{-->
<!--  background: none !important;-->
<!--}-->
<!--</style>-->
