<template>
  <div id="side_nav" :class="[$vuetify.rtl ? 'ar' : 'en', $store.state.side ? 'opened' : 'closed']">
    <div v-if="$store.state.side" class="items">
      <div class="h-100">
        <div class="item" v-for="(item, i) in $t('nav')" :key="i">
          <router-link class="position-relative" :to="paths[i]">
            <span v-if="i === 0">
              &nbsp;&nbsp;{{item}}&nbsp;&nbsp;
            </span>
            <span v-else>
              {{item}}
            </span>
            <span class="active-line" :class="$vuetify.rtl ? 'ar' : 'en'"></span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="between"></div>
    <div v-click-outside="closeMenu" class="language-container">
      <div @click="ToggleMenu" class="flex-row-reverse language">
        <div style="color: #FFFFFF">{{selected.label}}</div>
        <img id="arrow" :class="[this.$vuetify.rtl ? 'ml-1' : 'mr-1', 'indicator']" src="../../../assets/icons/indicator.svg">
      </div>
      <img :class="[$vuetify.rtl ? 'mr-2' : 'ml-2', 'd-block ball']" src="../../../assets/icons/langBtn.svg">
      <div v-if="this.isOpen" class="custom-dropdown-menu">
        <div id="transition" class="custom-dropdown-content">
          <div v-for="option in options" :key="option.value" @click="$setLang(option.value)" class="custom-dropdown-item">{{option.label}}</div>
        </div>
      </div>
    </div>
    <div class="between"></div>
    <div v-if="$store.state.side" class="side-footer">
      <div class="social">
        <a href="https://www.facebook.com/people/Doroob/61553104048638/?mibextid=ZbWKwL" target="_blank">
        <img src="../../../assets/icons/facebook.svg">
        </a>
        <a href="https://www.instagram.com/doroob258/?igsh=NWxlMnd3M29qcGw3" target="_blank">
        <img src="../../../assets/icons/instagram.svg">
        </a>
        <a href="https://www.youtube.com/@Doroob-gt9yy" target="_blank">
        <img src="../../../assets/icons/youtube.svg">
        </a>
      </div>
      <div class="mb-2">{{$t('contactUs.copyRight')}}&nbsp;@{{new Date().getFullYear()}}</div>
      <div class="terms"><span>{{$t('contactUs.privacyPolicy')}}</span>, &nbsp;<span>{{$t('contactUs.terms')}}</span></div>
    </div>
  </div>
</template>

<script>

import Direction from '../../Mixins/Lang/Direction'
export default {
  name: 'SideNav',
  mixins: [Direction],
  data: () => ({
    sideNav: false,
    options: [{ label: 'العربية', value: 'ar' }, { label: 'English', value: 'en' }],
    selected: {},
    isOpen: false,
    paths: ['/', '/#about', '/#vehicle_types', '/#service_centers', '/faqs', '/#contact']
  }),
  created () {
    window.addEventListener('resize', this.checkSide)
    this.selected = this.$vuetify.rtl ? { label: 'العربية', value: 'ar' } : { label: 'English', value: 'en' }
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      this.handleLang(val)
    })
  },
  mounted () {
    this.checkSide()
  },
  destroyed () {
    window.removeEventListener('resize', this.checkSide)
  },
  methods: {
    closeMenu () {
      if (this.isOpen) {
        document.getElementById('arrow').classList.remove('rotate')
        document.getElementById('transition').classList.remove('is-open')
        setTimeout(() => {
          this.isOpen = false
        }, 300)
      }
    },
    ToggleMenu () {
      if (!this.isOpen) {
        this.isOpen = true
        setTimeout(() => {
          document.getElementById('transition').classList.add('is-open')
          document.getElementById('arrow').classList.add('rotate')
        })
      } else {
        this.closeMenu()
      }
    },
    handleLang (value) {
      this.selected = value ? { label: 'العربية', value: 'ar' } : { label: 'English', value: 'en' }
      this.isOpen = false
      document.getElementById('arrow').classList.remove('rotate')
    },
    checkSide () {
      if (window.innerWidth > 900) {
        this.closeMenu()
      }
      // this.sideNav = document.getElementById('side_nav').style.width === '300px'
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/main";
.social {
  margin: 2rem 0;
}
.social img {
  margin: 0 2rem;
}
#side_nav {
  position: fixed;
  height: calc(100vh - 72px);
  top: 72px;
  z-index: 1001;
  background: rgba(18, 17, 24, 0.88);
  -webkit-transition: width .5s;
  transition: width .5s;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 0;
  a {
    img {
      width: 22px; height: 22px;
    }
  }
}
@media screen and (max-width: 900px){
  #side_nav {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}
#side_nav.opened {
  width: 300px;
}
#side_nav.closed {
  width: 0px;
}
.language > div{
  font-size: 15px;
  font-family: lama-regular !important;
}
.language-container > img {
  width: 22px;
  height: 22px
}
.custom-dropdown-item{
  font-size: 12px;
}
#side_nav.ar {
  left: 0;
}
#side_nav.en {
  right: 0;
}
.custom-dropdown-menu{
  bottom: calc(-98px - 0.5rem);
  top: unset;
}
@media screen and (min-width: 900px){
  #side_nav {
    display: none;
  }
}
.item {
  font-size: 16px!important;
  color: #FFFFFF;
  padding: calc(0.8rem + 1vh) 0;
  //margin: calc(1rem + 7vw) 0px;
}
.items {
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.between {
  height: 5%;
}
.language-container {
  min-height: 10%;
  width: max-content !important;
  margin: calc(0.5rem) auto calc(0.5rem) auto !important;
}
.side-footer {
  margin: 0 1rem;
  font-family: lama-bold;
  font-size: 13px;
  min-height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: orange;
  min-width: 265px;
}
.lang {
  margin: 1rem 0;
}
.router-link-exact-active .active-line {
  background: orange;
  position: absolute;
  bottom: -0.8rem;
  height: 2px;
}
.router-link-exact-active .active-line.ar {
  right: -50%;
  width: 200%;
}
.router-link-exact-active .active-line.en {
  right: -25%;
  width: 150%;
}
.terms span {
  text-decoration: underline; font-size: 12px;
}
</style>
