<template>
  <div id="faqs">
    <top-nav/>
    <side-nav v-click-outside="closeSide"/>
    <div class="faqsContent">
      <div class="faqsMainTitle">
        الأسئلة الشائعة
      </div>
      <div class="secoundTitle">
        إليكم قائمة بالأسئلة الأكثر تداولاً بين عملائنا الكرام
      </div>
      <div class="questions">
        <Accordion expandIcon="pi-plus" collapseIcon="pi-minus">
          <AccordionTab v-for="tab in tabs" :key="tab.title" :header="tab.title"  >
            <p v-html="tab.content"></p>
          </AccordionTab>
        </Accordion>

      </div>
      <div class="clipped-layer"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Accordion from 'primevue/accordion'
import TopNav from '../../Core/Compontents/Navs/TopNav'
import AccordionTab from 'primevue/accordiontab'
import SideNav from '../../Core/Compontents/Navs/SideNav'

export default {
  name: 'index',
  components: { TopNav, Accordion, AccordionTab, SideNav },
  data () {
    return {
      active: 0,
      tabs: [
        {
          title: '1- كم تبلغ تكلفة الفحص الفني؟',
          content: '<ul>\n' +
            '    <li>قيمة بدل خدمة الفحص الفني للمركبات الخفيفة 90.000 ل.س&nbsp;</li>\n' +
            '    <li>قيمة بدل خدمة الفحص الفنيللمركبات الكبيرة 180.000 ل.س&nbsp;</li>\n' +
            '    <li>قيمة بدل خدمة الفحص الفني للدراجات النارية 25.000 ل.س&nbsp;</li>\n' +
            '</ul>'
        },
        {
          title: '2- ما هي خطوات الفحص الفني؟ ',
          content: `<ul dir="rtl">
<li>القسم الأول :</li>
</ul>
<ul dir="rtl" style="list-style-type: circle;">
<li>&nbsp;فحص نظري</li>
<li>فحص انبعاث الغازات</li>
</ul>
<ul dir="rtl">
<li>القسم الثاني :&nbsp;</li>
</ul>
<ul dir="rtl" style="list-style-type: circle;">
<li>الانحراف</li>
<li>المخمدات</li>
<li>شدة الفرملة</li>
</ul>
<ul dir="rtl">
<li>القسم الثالث :&nbsp;</li>
</ul>
<ul dir="rtl" style="list-style-type: circle;">
<li>المحاور</li>
</ul>`
        },
        {
          title: '3- ما هي أنواع المركبات التي يمكن فحصها؟ ',
          content: `<ul>
    <li>سيارات&nbsp;</li>
    <li>شاحنات&nbsp;</li>
    <li>دراجات</li>
    <li>آليات خاصة</li>
</ul>`
        },
        {
          title: '4-  ما هي النقاط الواجب مراعاتها قبل الفحص الفن والتي تؤدي إلى عدم الإجتياز؟ ',
          content: `<ul>
<li>عدم وجود أنوار أمامية أو خلفية أو وجود أنوار معطلة&nbsp;</li>
<li>كسر في الزجاج الأمامي جهة السائق تحجب الرؤية</li>
<li>تسريب وقود</li>
<li>التأكد من فعالية الفرامل وعدم وجود تسريب في زيت الفرامل&nbsp;</li>
<li>التأكد من جهوزية الميزانية الأمامية والخلفية</li>
<li>عدم وجود دخان كثيف للسيارات العاملة على الديزل</li>
</ul>`
        },
        {
          title: '5-  ما هي خطوات حجز موعد مسبق للفحص؟  ',
          content: `<ul>
<li>الدخول الى موقع الدروب <span style="text-decoration: underline;"><a href="http://www.doroob-sy.com">www.doroob-sy.com</a></span></li>
<li>الدخول على احجز موعد&nbsp;</li>
<li>نقوم بإدخال رمز التحقق المكتوب أعلاه</li>
<li>نقوم باتباع الخطوات الموجودة من اختيار المحافظة واختيار مكان الحجز مثلاً (دمشق - زبلطاني - ريف دمشق - حرستا - النبك ) , نوع المركبة ( خفيفة - ثقيلة - مختلطة ... )</li>
<li>ثم نقوم باختيار تحديد موعد يوم الفحص من الأيام المتوفرة للفحص&nbsp;</li>
</ul>`
        }
      ]
    }
  },
  mounted () {
    this.checkLang()
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      console.log(val)
      if (this.$vuetify.rtl === true) {
        $('.p-accordion-toggle-icon').css('left', '0')
        $('.p-accordion-toggle-icon').css('right', 'unset')
      } else if (this.$vuetify.rtl === false) {
        $('.pi-plus').css('color', 'white')
        $('.p-accordion-toggle-icon').css('left', 'unset')
        $('.p-accordion-toggle-icon').css('right', '0')
      }
    })
  },
  methods: {
    closeSide (val) {
      if (val.clientY > 70) {
        this.$store.state.side = false
      }
    },
    checkLang () {
      if (this.$vuetify.rtl === true) {
        $('.p-accordion-toggle-icon').css('left', '0')
        $('.p-accordion-toggle-icon').css('right', 'unset')
      } else if (this.$vuetify.rtl === false) {
        $('.pi-plus').css('color', 'white')
        $('.p-accordion-toggle-icon').css('left', 'unset')
        $('.p-accordion-toggle-icon').css('right', '0')
      }
    }
  }
}
</script>

<style scoped>
  @import "~primevue/resources/primevue.min.css";
  @import "~primeicons/primeicons.css";
.faqsContent {
  background: rgba(18, 17, 24, 0.9);
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  position: relative;
  z-index: 10
  }
  .faqsMainTitle {
    color: #FFAC00;
    padding-top: 45px;
    font-size: xx-large;
    font-weight: bold;
  }
  .secoundTitle {
    color: white;
    font-weight: bold;
  }
  .questions {
    padding: 65px 10vw;
  }
  .clipped-layer {
    position: absolute;
    height: 100% !important;
    top: 0;
    z-index: -1;
    background: #121118;
    background-size: cover;
    height: 100vh;
    width: 72.2%;
    right: 0;
    clip-path: polygon( 57.843% 100%,94.668% 0%,100% 0%,100% 100%,0% 100%,71.256% 0%,89.99% 0%,39.595% 100% );
  }
  @media screen and (max-width: 1000px) and (min-width: 470px) {
    .clipped-layer {
      width: 200%!important;
      right: -42%;
    }
  }
  @media screen and (max-width: 470px) {
    .clipped-layer {
      width: 200%!important;
      right: -45%;
    }
  }
  @media screen and (max-width: 1000px) {
    /*.clipped-layer {*/
    /*  background: rgba(255, 255, 255, 0.8) !important;*/
    /*}*/
  }

</style>
<style>
  .p-accordion-content {
    line-height: 1.5;
    margin: 0;
    color: white;
    padding: 40px 0 0 0;
    text-align: start;
  }
  /*.p-panel-icons {*/
  /*  color: rgb(255, 172, 0);*/
  /*}*/
  .p-accordion-header-text {
    color: white;
    position: relative;
    font-weight: bold;
  }
  .p-accordion-toggle-icon {
    position: absolute;
  }
  .pi-plus, .pi-minus {
    color: #FFAC00 !important;
  }
  .p-accordion-tab{
    padding: 45px 0;
  }
   .p-accordion-tab {
     min-height: 60px;
     padding: 50px 0;
     border-bottom: 1.5px #FFAC00 solid;
   }
  .p-accordion-tab-active .p-accordion-header-text {
    color: #FFAC00 !important;

  }
  .p-accordion-content ul {
    padding: 0 24px !important;
  }
</style>
